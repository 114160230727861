import {Pagination, PaginationItem} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import {useTranslation} from '../../tools/i18n';
import Link from "next/link";
import {useRouter} from "next/router";
import Button from "@material-ui/core/Button";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 40
	},
	itemWrapper: {
		height: 32,
		fontSize: 12,
		lineHeight: '32px',
		backgroundColor: '#f5f5f5',
		borderRadius: 4,
		'&:hover': {
			backgroundColor: '#fff'
		}
	},
	paginationTitle: {
		marginBottom: 10,
		fontSize: 12,
		color: '#999'
	},
	paginationItem: {
		margin: '0 3px',
    	padding: '0 8px',
		height: 32,
		minWidth: 32,
		fontSize: 13,
		lineHeight: '32px',
		borderRadius: 4,
		backgroundColor: '#f5f5f5',
		'&.selected': {
			backgroundColor: '#eaeaea'
		},
		'&.disabled': {
			color: 'rgba(0, 0, 0, 0.87)'
		},
		'&:hover': {
			backgroundColor: 'white'
		}
	},
	paginationIcon: {
		fontSize: 20
	}
}), {name: 'ProductPagination'});

export default function ProductPagination({currentPage, maxPage, productPerPage, onChange}) {
	const classes = useStyles();
	const {t} = useTranslation();
	const router = useRouter();
	const {query, asPath} = router;
	const { page, order, slug, initial, ...filters } = query

	function renderItem(item) {
		if(item.disabled) {
			return '';
		}

		let href = {
			pathname: asPath.split('?')[0],
			query: {
				...filters
			}
		};

		if(item.page > 1) {
			href.query.page = item.page;
		}

		if(order) {
			href.query.order = order;
		}

		let text = '';
		switch (item.type) {
			case 'page':
				text = item.page;
				break;
			case 'start-ellipsis':
			case 'end-ellipsis':
				text = '...';
				break;
			case 'next':
				text = <ChevronRight className={classes.paginationIcon}/>;
				break;
			case 'previous':
				text = <ChevronLeft className={classes.paginationIcon}/>;
				break;
		}

		return item.type != 'start-ellipsis' && item.type != 'end-ellipsis' ? <Link
			href={href}
			classes={{
				root: classes.itemWrapper
			}}
		><a style={{color: "inherit", textDecoration: 'none'}}><Button className={[classes.paginationItem, item.selected ? 'selected' : ''].join(' ')}>{text}</Button></a></Link> : <Button className={[classes.paginationItem, item.selected ? 'selected' : '', 'disabled'].join(' ')} disabled={true}>{text}</Button>
	}

	return (
		maxPage > 0 ?
		<div className={classes.wrapper}>
			<Typography
				variant="body1"
				classes={{
					root: classes.paginationTitle
				}}
			>{t('pagination', {page: currentPage, count: maxPage})}</Typography>
			<Pagination
				boundaryCount={1}
				page={currentPage}
				count={maxPage}
				onChange={onChange}
				siblingCount={1}
				renderItem={renderItem}/>
		</div> : ''
	);
}